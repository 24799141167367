@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  user-select: none;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../public/fonts/aeonik/AeonikTRIAL-Regular.otf') format('opentype');
  font-weight: 400;
  /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik';
  src: url('../public/fonts/aeonik/AeonikTRIAL-Bold.otf') format('opentype');
  font-weight: 700;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../public/fonts/Euclid_Circular/Euclid\ Circular\ A\ Bold.ttf') format('opentype');
  font-weight: 700;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../public/fonts/Euclid_Circular/Euclid\ Circular\ A\ Medium.ttf') format('opentype');
  font-weight: 500;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../public/fonts/Euclid_Circular/Euclid\ Circular\ A\ SemiBold.ttf') format('opentype');
  font-weight: 600;
  /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../public/fonts/Euclid_Circular/Euclid\ Circular\ A\ Regular.ttf') format('opentype');
  font-weight: 400;
  /* Bold */
  font-style: normal;
}


/* Full-screen loading wrapper */
.loading-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

/* Gradient background */
.background-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #B48EDD, #6F3CC6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Logo styling */
.logo {
  width: 160px;
  margin-bottom: 40px;
}

/* Spinner */
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}

#countdown {
  position: relative;
  margin: auto;
  margin-top: 100px;
  height: 40px;
  width: 40px;
  text-align: center;
}



@keyframes heartbeatBackground {

  0%,
  100% {
    background-color: black;
  }

  50% {
    background-color: #333;
    /* A slightly lighter black for the pulse effect */
  }
}

.heartbeat-background-button {
  animation: heartbeatBackground 1.5s infinite;
}